'use client';
import { Faq as StrapiFaq } from '@strapi-schema';
import { default as MotivatedFaq } from '@wearemotivated/design-system/pages/shared/layouts/Faq/Faq';
import React, { FC } from 'react';

interface Props {
  data: StrapiFaq;
}

const Faq: FC<Props> = (props) => {
  const { data } = props;
  return (
    <MotivatedFaq
      items={data.items.map((item) => ({
        title: item.question ?? '',
        content: item.answer ?? '',
      }))}
      title={data.title ?? ''}
    />
  );
};

export default Faq;
