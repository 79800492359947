'use client';

import type { ProductPageData } from '@/app/api/strapi/types';
import {
  MarkdownText,
  TitleLarge,
} from '@wearemotivated/design-system/components/Typography/Typography';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  FlexibleAccordion,
} from '@wearemotivated/design-system/components/FlexibleAccordion/FlexibleAccordion';
import Container from '@wearemotivated/design-system/components/Container';
import { cn } from '@wearemotivated/design-system/lib/utils';

export const Faq = ({
  data: faq,
  className,
}: {
  data: ProductPageData['faq'];
  className?: string;
}) => {
  return (
    <div className={cn('mb-10 mt-20 lg:mt-24', className)}>
      <Container className="px-5 sm:px-9 lg:px-20">
        <TitleLarge className="mb-4 text-5xl font-medium sm:mb-8 sm:text-7xl sm:font-normal">
          {faq.title}
        </TitleLarge>
        <FlexibleAccordion className="w-full">
          {faq.items.map((faqEntry) => (
            <AccordionItem key={faqEntry.id} value={String(faqEntry.id)}>
              <AccordionTrigger>{faqEntry.question}</AccordionTrigger>
              <AccordionContent className="accordion-content">
                <MarkdownText>{faqEntry.answer ?? ''}</MarkdownText>
              </AccordionContent>
            </AccordionItem>
          ))}
        </FlexibleAccordion>
      </Container>
    </div>
  );
};
